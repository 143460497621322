import '../App.css';

function Home() {
    return (
        <div className="home">
            <div className={"title"}>Willkommen bei Zwielimmo | Ihr vertrauensvoller Partner rund um Ihre Immobilie.</div>
            <div className={"subtitle"}>Ihr Partner für kundige Immobilienverwaltung</div>
            <div className={"text"}>Sind Sie auf der Suche nach einer passenden{' '}<strong className={"bold"}>Immobilienverwaltung</strong>? Gerne unterstützen wir Sie bei der Bewirtschaftung und Betreuung Ihrer Liegenschaft/en.</div>
            <div className={"text"}>Egal, ob Verwaltung, Neuvermietung von Immobilien, Ihre Anfragen bearbeiten und beantworten wir diskret. Eine vertrauensvolle Zusammenarbeit ist uns sehr wichtig, um Ihre Bedürfnisse zu erfassen und im Tagesgeschäft umzusetzen. Die richtige Kommunikation entscheidet über so viel. Damit wir auf die vereinzelten Bedürfnisse eingehen können, steht Kundennähe und Transparenz im Fokus.</div>
            <div className={"text"}>Wir freuen uns auf Ihre Kontaktaufnahme.</div>
        </div>
    );
}

export default Home;
