import '../App.css';

function Dienstleistungen() {
    return (
        <div className="">
            Dienstleistungen
        </div>
    );
}

export default Dienstleistungen;
