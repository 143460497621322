import '../App.css';

function Dokumente() {
    return (
        <div className="">
            Dokumente
        </div>
    );
}

export default Dokumente;
