import logo from './IZW_logo_text.png';
import {BrowserRouter, Link, NavLink, Route} from "react-router-dom";
import './App.css';
import Home from "./Sites/Home";
import Dokumente from "./Sites/Dokumente";
import Dienstleistungen from "./Sites/Dienstleistungen";
import Kontakt from "./Sites/Kontakt";
import {Routes} from "react-router";

function App() {
  return (
    <div className="App">
        <BrowserRouter>
            <header className={'header'}>
                <Link to="/"><img className={'logo'} src={logo} alt={"Logo Zwiel Immo"}/></Link>
                <nav className={'nav'}>
                    <NavLink to="/" className="NavLink">Home</NavLink>
                    <NavLink to="/dienstleistungen" className="NavLink">Dienstleistungen</NavLink>
                    <NavLink to="/dokumente" className="NavLink">Dokumente</NavLink>
                    <NavLink to="/kontakt" className="NavLink">Kontakt</NavLink>
                </nav>
            </header>
            <div className={"app-content"}>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/dienstleistungen" element={<Dienstleistungen/>}/>
                    <Route path="/dokumente" element={<Dokumente/>}/>
                    <Route path="/kontakt" element={<Kontakt/>}/>
                </Routes>
            </div>
            <footer className={"footer"}>
                Kontakt: Zwielimmo | Tina Gajger | Tel: xxx xxx xx xx | info@xxxx.ch
            </footer>
        </BrowserRouter>
    </div>
  );
}

export default App;
