import '../App.css';

function Kontakt() {
    return (
        <div className="">
            Kontakt
        </div>
    );
}

export default Kontakt;
